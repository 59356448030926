import React from 'react';

import StringStepComponentSpec from '@assured/step-renderer/types/step-components/String';

export const transformDisplay = (val?: string) => {
  if (!val) {
    return '';
  }
  // Strip all non numbers
  let displayVal = val.replace(/[^0-9|\\*]/g, '');
  displayVal = displayVal.substring(0, 9);

  displayVal = displayVal
    .split('')
    .map((x, i) => {
      if (i === 3) {
        return '-*';
      }
      if (i < 5) {
        return '*';
      }
      if (i === 5) {
        return `-${x}`;
      }
      return x;
    })
    .join('');

  return displayVal;
};

export const transformValue = (unmaskedValue?: string, rawInput?: string) => {
  if (!rawInput) {
    return '';
  }
  const cleanVal = [];
  const dashlessRaw = rawInput.replaceAll('-', '');
  for (let i = 0; i < dashlessRaw.length; i++) {
    const raw = dashlessRaw[i];
    const unmasked = unmaskedValue?.[i];
    cleanVal[i] =
      raw !== '*' && /[0-9]/g.exec(raw)
        ? // print new numeric character
          (cleanVal[i] = raw)
        : // print existing numeric character
          (cleanVal[i] = unmasked);
  }
  return cleanVal.join('');
};

export type SSNMaskProps = {
  value?: string;
  step_component: StringStepComponentSpec;
  updateValue: (field: string | undefined, value: string | null) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const SSNMask: React.FC<SSNMaskProps> = ({
  step_component,
  value,
  updateValue,
  ...rest
}) => {
  return (
    <input
      type="text"
      value={transformDisplay(value)}
      // this is unfortunate but it's pretty deep and there are a ton
      // of possible html attributes
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onChange={e => {
        const raw = e.target.value;
        const clean = transformValue(value, raw);
        updateValue(step_component.field, clean);
      }}
    />
  );
};
