import { CommercialAutoVehicleType } from '~/src/generatedX/graphql';

import tractor from '../../../../images/commercial_auto_vehicles/01 Tractor.png';
import dumpTruck from '../../../../images/commercial_auto_vehicles/02 Dump Truck.png';
import flatbedTruck from '../../../../images/commercial_auto_vehicles/03 Flatbed Truck.png';
import pickup from '../../../../images/commercial_auto_vehicles/05, 5A, 5B, 55, 58, 61 Pickup.png';
import boxVan from '../../../../images/commercial_auto_vehicles/06 Mobile Medical Lab and Box Van, Mobile Vet Labs.png';
import streetSweeper from '../../../../images/commercial_auto_vehicles/08 Street Sweeper.png';
import tankTruck from '../../../../images/commercial_auto_vehicles/08 Tank truck, Steet Sweeper.png';
import miniVan from '../../../../images/commercial_auto_vehicles/09 Mini Van.png';
import tankTrailer from '../../../../images/commercial_auto_vehicles/12 Tank Trailer.png';
import flatbedTrailer from '../../../../images/commercial_auto_vehicles/13 Flatbed Trailer.png';
import livestockTrailer from '../../../../images/commercial_auto_vehicles/15 Livestock trailer.png';
import refrigeratedDryFreightTrailer from '../../../../images/commercial_auto_vehicles/17 Refrigerated Dry Freight Trailer.png';
import horseTrailer from '../../../../images/commercial_auto_vehicles/18 Horse Trailer 1 - 2 stalls.png';
import lowboyTrailer from '../../../../images/commercial_auto_vehicles/19 Lowboy Trailer.png';
import bulkCommodityTrailer from '../../../../images/commercial_auto_vehicles/21 Bulk Commodity Trailer.png';
import bottomDumpGrainTrailer from '../../../../images/commercial_auto_vehicles/23 Bottom Dump Grain Trailer.png';
import autoHaulingTrailer from '../../../../images/commercial_auto_vehicles/26 Auto Hauling Trailer.png';
import sportAuto from '../../../../images/commercial_auto_vehicles/27 Sport Auto.png';
import largeHorseTrailer from '../../../../images/commercial_auto_vehicles/28 Large Horse Trailer.png';
import utilityTrailer from '../../../../images/commercial_auto_vehicles/28 Utility Trailer.png';
import luxuryAuto from '../../../../images/commercial_auto_vehicles/29 Luxury Auto.png';
import privatePassengerAuto from '../../../../images/commercial_auto_vehicles/30 Private Passenger Auto.png';
import motorHome from '../../../../images/commercial_auto_vehicles/31 Motor Home.png';
import utilityVehicle from '../../../../images/commercial_auto_vehicles/32 Utility Vehicle.png';
import stepVan from '../../../../images/commercial_auto_vehicles/33 Step Van.png';
import luxurySUV from '../../../../images/commercial_auto_vehicles/34 Luxury SUV.png';
import deliveryVan from '../../../../images/commercial_auto_vehicles/35 Delivery Van.png';
import limousine from '../../../../images/commercial_auto_vehicles/37, 39, 49 Limousine.png';
import hearse from '../../../../images/commercial_auto_vehicles/38 Hearse.png';
import straightTruck from '../../../../images/commercial_auto_vehicles/40 Straight Truck.png';
import straightTruckGreater from '../../../../images/commercial_auto_vehicles/42 Straight Truck -  GVW_ Greater than 26,000.png';
import iceCreamTruck from '../../../../images/commercial_auto_vehicles/46 Ice Cream Truck.png';
import travelTrailer from '../../../../images/commercial_auto_vehicles/48 Travel Trailer.png';
import towTruckSingleAxle from '../../../../images/commercial_auto_vehicles/4A, 4B Tow Truck Single Axle.png';
import cementMixer from '../../../../images/commercial_auto_vehicles/50 Cement Mixer.png';
import towTruckTwoAxle from '../../../../images/commercial_auto_vehicles/54 Tow Truck - Two axle.png';
import cateringTruck from '../../../../images/commercial_auto_vehicles/56 Catering Truck.png';
import garbageTruck from '../../../../images/commercial_auto_vehicles/65 Garbage Truck.png';
import wheelchairBus from '../../../../images/commercial_auto_vehicles/6D Wheelchair bus.png';
import frontLoader from '../../../../images/commercial_auto_vehicles/74 Front Loader (Garbage truck).png';
import bucketTruck from '../../../../images/commercial_auto_vehicles/77 Bucket Truck.png';
import gooseneckTrailer from '../../../../images/commercial_auto_vehicles/82 Gooseneck Trailer.png';
import pumpTruck from '../../../../images/commercial_auto_vehicles/87 Pump Truck.png';
import bus9To15Passengers from '../../../../images/commercial_auto_vehicles/91 Bus (9-15 passengers).png';
import bus16To60Passengers from '../../../../images/commercial_auto_vehicles/92 Bus (16-60 passengers).png';
import bus61PlusPassenger from '../../../../images/commercial_auto_vehicles/93 Bus (61+ passenger).png';
import boomCraneTruck from '../../../../images/commercial_auto_vehicles/95, 96, 97 Boom Crane truck.png';

export const CommercialAutoVehicleThumbnail = ({
  type,
}: {
  type: CommercialAutoVehicleType;
}) => {
  let src = '';
  // eslint-disable-next-line default-case
  switch (type) {
    case CommercialAutoVehicleType.Tractor:
      src = tractor;
      break;
    case CommercialAutoVehicleType.DumpTruckGvw_0_16K:
    case CommercialAutoVehicleType.DumpTruckGvw_16K_33K:
    case CommercialAutoVehicleType.DumpTruckGvw_45KPlus:
      src = dumpTruck;
      break;
    case CommercialAutoVehicleType.FlatbedTruckGvw_0_16K:
    case CommercialAutoVehicleType.FlatbedTruckGvw_16K_26K:
    case CommercialAutoVehicleType.FlatbedTruckGvw_26KPlus:
      src = flatbedTruck;
      break;
    case CommercialAutoVehicleType.TowTruckSingleAxleGvw_0_26K:
    case CommercialAutoVehicleType.TowTruckSingleAxleGvw_26KPlus:
      src = towTruckSingleAxle;
      break;
    case CommercialAutoVehicleType.TowTruck_2Axle:
      src = towTruckTwoAxle;
      break;
    case CommercialAutoVehicleType.PickupTruckHalfTonPlus_4X2:
    case CommercialAutoVehicleType.PickupTruckHalfTonPlus_4X4:
    case CommercialAutoVehicleType.PickupTruck_0HalfTon_4X2:
    case CommercialAutoVehicleType.PickupTruck_0HalfTon_4X4:
    case CommercialAutoVehicleType.PickupWithFifthWheelHitch:
      src = pickup;
      break;
    case CommercialAutoVehicleType.WheelchairVan:
    case CommercialAutoVehicleType.CargoPassengerVan:
      src = boxVan;
      break;
    case CommercialAutoVehicleType.WheelchairBus_9_15Passengers:
    case CommercialAutoVehicleType.WheelchairBus_16_60Passengers:
    case CommercialAutoVehicleType.WheelchairBus_61PlusPassengers:
      src = wheelchairBus;
      break;
    case CommercialAutoVehicleType.StreetSweeper:
      src = streetSweeper;
      break;
    case CommercialAutoVehicleType.TankTruckBelow_1401Gallons:
    case CommercialAutoVehicleType.TankTruck_1401GallonsPlus:
      src = tankTruck;
      break;
    case CommercialAutoVehicleType.Minivan:
      src = miniVan;
      break;
    case CommercialAutoVehicleType.TankTrailer:
      src = tankTrailer;
      break;
    case CommercialAutoVehicleType.FlatbedTrailer:
      src = flatbedTrailer;
      break;
    case CommercialAutoVehicleType.LivestockTrailer:
      src = livestockTrailer;
      break;
    case CommercialAutoVehicleType.RefrigeratedDryFreightTrailer:
      src = refrigeratedDryFreightTrailer;
      break;
    case CommercialAutoVehicleType.HorseTrailer_1_2Stalls:
      src = horseTrailer;
      break;
    case CommercialAutoVehicleType.LargeHorseTrailer:
      src = largeHorseTrailer;
      break;
    case CommercialAutoVehicleType.LowboyTrailer:
      src = lowboyTrailer;
      break;
    case CommercialAutoVehicleType.BulkCommodityTrailer:
      src = bulkCommodityTrailer;
      break;
    case CommercialAutoVehicleType.BottomDumpGrainTrailer:
      src = bottomDumpGrainTrailer;
      break;
    case CommercialAutoVehicleType.AutoHaulingTrailer:
      src = autoHaulingTrailer;
      break;
    case CommercialAutoVehicleType.SportAuto:
      src = sportAuto;
      break;
    case CommercialAutoVehicleType.UnenclosedUtilityTrailer_0_12Ft:
    case CommercialAutoVehicleType.UnenclosedUtilityTrailer_13PlusFt:
    case CommercialAutoVehicleType.EnclosedUtilityTrailer_0_12Ft:
    case CommercialAutoVehicleType.EnclosedUtilityTrailer_13PlusFt:
      src = utilityTrailer;
      break;
    case CommercialAutoVehicleType.MotorHome:
      src = motorHome;
      break;
    case CommercialAutoVehicleType.StepVanGvw_0_10K:
      src = stepVan;
      break;
    case CommercialAutoVehicleType.DeliveryVanGvw_10KPlus:
      src = deliveryVan;
      break;
    case CommercialAutoVehicleType.Limousine_0_120Inches:
    case CommercialAutoVehicleType.Limousine_121_180Inches:
    case CommercialAutoVehicleType.Limousine_181PlusInches:
      src = limousine;
      break;
    case CommercialAutoVehicleType.Hearse:
      src = hearse;
      break;
    case CommercialAutoVehicleType.StraightTruckGvw_0_16K:
      src = straightTruck;
      break;
    case CommercialAutoVehicleType.StraightTruckGvw_26KPlus:
      src = straightTruckGreater;
      break;
    case CommercialAutoVehicleType.IceCreamTruck:
      src = iceCreamTruck;
      break;
    case CommercialAutoVehicleType.TravelTrailer:
      src = travelTrailer;
      break;
    case CommercialAutoVehicleType.CementMixer:
      src = cementMixer;
      break;
    case CommercialAutoVehicleType.CateringTruck:
      src = cateringTruck;
      break;
    case CommercialAutoVehicleType.GarbageTruckGvw_0_45K:
    case CommercialAutoVehicleType.GarbageTruckGvw_45KPlus:
      src = garbageTruck;
      break;
    case CommercialAutoVehicleType.FrontLoaderGvw_0_45K:
    case CommercialAutoVehicleType.FrontLoaderGvw_45KPlus:
      src = frontLoader;
      break;
    case CommercialAutoVehicleType.BucketTruckGvw_0_16K:
    case CommercialAutoVehicleType.BucketTruckGvw_16K_26K:
    case CommercialAutoVehicleType.BucketTruckGvw_26KPlus:
      src = bucketTruck;
      break;
    case CommercialAutoVehicleType.GooseneckTrailer:
      src = gooseneckTrailer;
      break;
    case CommercialAutoVehicleType.PumpTruckGvw_0_16K:
    case CommercialAutoVehicleType.PumpTruckGvw_16K_26K:
    case CommercialAutoVehicleType.PumpTruckGvw_26KPlus:
      src = pumpTruck;
      break;
    case CommercialAutoVehicleType.Bus_9_15Passengers:
      src = bus9To15Passengers;
      break;
    case CommercialAutoVehicleType.Bus_16_60Passengers:
      src = bus16To60Passengers;
      break;
    case CommercialAutoVehicleType.Bus_61PlusPassengers:
      src = bus61PlusPassenger;
      break;
    case CommercialAutoVehicleType.BoomCraneGvw_0_16K:
    case CommercialAutoVehicleType.BoomCraneGvw_16K_26K:
    case CommercialAutoVehicleType.BoomCraneGvw_26KPlus:
      src = boomCraneTruck;
      break;
    case CommercialAutoVehicleType.LuxuryAuto:
      src = luxuryAuto;
      break;
    case CommercialAutoVehicleType.PrivatePassengerAuto:
      src = privatePassengerAuto;
      break;
    case CommercialAutoVehicleType.UtilityVehicle:
      src = utilityVehicle;
      break;
    case CommercialAutoVehicleType.LuxurySuv:
      src = luxurySUV;
      break;
  }

  if (!src) {
    return null;
  }

  return (
    <div className="h-12 overflow-hidden flex items-center justify-center">
      <img
        src={src}
        alt={`${type.replaceAll('_', ' ')}`}
        className="max-h-full max-w-full object-contain mix-blend-multiply"
      />
    </div>
  );
};
