import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { OccupantEntryStepComponentSpec } from '~/types/step-components/OccupantEntry';

import {
  AnyStepComponentSpec,
  ClaimWorkflowStepFragmentData,
  DateTimeStepComponentSpec,
  DateTimeWithoutTimezoneStepComponentSpec,
  DocumentStepComponentSpec,
  ListStepComponentSpec,
  SelectOrCreateStepComponentSpec,
  StringStepComponentSpec,
} from '../';
import { ListMemberField } from '../types/step-components/List';
import { isReadyToContinue as isUnderwritingMemoPhoneReady } from '../types/step-components/UnderwritingMemoPhone';

// For the DateTimeWithoutTimezone component, we allow users to enter
// values up to 6 hours in the future, since we don't yet know the
// incident timezone.
export const UNKNOWN_TIMEZONE_BUFFER_MS = 1000 * 60 * 60 * 6;

const ONE_DAY = 1000 * 60 * 60 * 24;

export const validatePhoneNumber = (value: any) => isPossiblePhoneNumber(value);

export const generateValidationSchema = ({
  step,
}: {
  step: ClaimWorkflowStepFragmentData;
}) => {
  const skippable = !!step.content.skip_label || !!step.content.skippable;

  const shape = {};

  const makeComponentSpec = (component: AnyStepComponentSpec) => {
    let v;

    if (component.type === 'string') {
      v = yup.string().nullable();
      if (
        component.required ||
        (!skippable && !component.optional && component.required !== false)
      ) {
        v = v.required('Please fill out this field.');
      }

      if (
        component.mode === 'postal_code' &&
        (component.required ||
          (!skippable && !component.optional && component.required !== false))
      ) {
        v = v.min(5, 'Please enter at least 5 characters.');
      }

      if (component.mode === 'phone_number') {
        v = v.test(
          'is-valid-phone-number',
          'Please enter a valid phone number.',
          (value: any) =>
            (!value &&
              (skippable ||
                component.optional ||
                component.required === false)) ||
            (value && validatePhoneNumber(value)),
        );
      } else if (component.mode === 'email') {
        v = v.email('Please enter a valid email address.');
      } else if (component.mode === 'license_plate') {
        v = v.test(
          'is-valid-license-plate',
          'Please enter a valid license number.',
          (value: any) =>
            (!value && skippable) || /^[A-Z0-9 ]{3,10}$/.test(value),
        );
      } else if (component.mode === 'full_name' || component.mode === 'name') {
        v = v.test(
          'is-valid-full-name',
          'Please enter both a first and last name.',
          (value: any) => {
            // If the field is not required, we allow empty values
            if (
              !value &&
              (component.required === false || component.optional === true)
            ) {
              return true;
            }

            return /\w+\s+\w+/.test(value);
          },
        );
      } else if (component.mode === 'insurance_policy_number') {
        v = v.test(
          'is-valid-insurance-policy-number',
          'Please enter a valid insurance policy number.',
          (value: any) => {
            if (!component.required && !value) {
              return true;
            }
            return value?.length > 3 && value?.length < 40;
          },
        );
      } else if (component.mode === 'insurance_claim_number') {
        v = v.test(
          'is-valid-insurance-claim-number',
          'Please enter a valid insurance claim number.',
          (value: any) => {
            if (!component.required && !value) {
              return true;
            }
            return value?.length > 3 && value?.length < 40;
          },
        );
      } else if (component.mode === 'currency') {
        v = v.test(
          'is-reasonable-currency',
          'Please enter a value less than $10,000,000, or call us for assistance.',
          (value: any) => value < 10_000_000 * 100,
        );
      } else if (
        component.mode === 'number' ||
        component.mode === 'percentage'
      ) {
        if (component.maximum) {
          v = v.test(
            'is-below-max',
            `Please enter a value less than or equal to ${component.maximum}.`,
            (value: any) =>
              +value <= (component as StringStepComponentSpec).maximum!,
          );
        }
        if (component.minimum) {
          v = v.test(
            'is-above-min',
            `Please enter a value greater than or equal to ${component.minimum}.`,
            (value: any) =>
              +value >= (component as StringStepComponentSpec).minimum!,
          );
        }
      }
    } else if (component.type === 'list') {
      v = yup.array();
      v = v.test('unique_values', '', function (value: any) {
        const uniqueFields = (
          component as ListStepComponentSpec
        ).list_components.filter(x => x.uniqueListEntry);

        if (!uniqueFields.length) {
          return true;
        }
        const hasUnknownToggle = !!(component as ListStepComponentSpec)
          .unknown_toggle;
        for (let i = 0; i < uniqueFields.length; i++) {
          const uniqueFieldType = uniqueFields[i];
          const hash: { [key: string | number]: number } = {};
          for (let j = 0; j < value.length; j++) {
            const response = value[j];
            const responseValue =
              response[uniqueFieldType.field as keyof typeof response];
            // terrible logic, I know, sorry.  Basically if this list
            // has the unknown_toggle property set that means it's sending "null" values
            // for the field.  If it's a multi-select, that will trip the unique violation.
            // So if unknown_toggle is set and the value is "null" then it shouldn't throw this error.
            if (
              (!hasUnknownToggle || responseValue !== null) &&
              hash.hasOwnProperty(responseValue)
            ) {
              return this.createError({
                message:
                  'You have specified the same item more than once. Please edit your entries to remove duplicate values.',
              });
            }
            hash[responseValue as keyof typeof hash] = 1;
          }
        }
        return true;
      });

      if (component.required) {
        v = v.min(1, 'Please list at least one.');
        if (component.fixed_length) {
          v = v.test(
            'is-required-fixed-length',
            'Please complete every field.',
            (value: any) => {
              if (!Array.isArray(value)) {
                return false;
              }
              if (
                value.length !==
                (component as ListStepComponentSpec).fixed_length
              ) {
                return false;
              }
              for (const subdef of (component as ListStepComponentSpec)
                .list_components) {
                if (
                  subdef.required &&
                  !value.every((v: any) => !!v[subdef.field!])
                ) {
                  return false;
                }
              }
              return true;
            },
          );
        }
      }
      const errorMessage = `This is a required field. ${
        component.list_components.length === 1
          ? 'Please edit or remove any empty items to proceed.'
          : ''
      }`;
      if (component.list_components.some(x => x.required)) {
        const schema = component.list_components.reduce(
          (acc: any, x: ListMemberField) => {
            if (!x.field) {
              throw new Error('a list component must have a field property');
            }

            if (x.required) {
              acc[x.field] = yup.string().required(errorMessage);
            } else {
              acc[x.field as keyof typeof acc] = yup.string().nullable();
            }
            return acc;
          },
          {},
        );
        const obj = yup.object(schema);
        return yup.array(obj);
      }
    } else if (component.type === 'select_multi') {
      v = component.single_toggle ? yup.mixed() : yup.array();
      if (component.required) {
        v = v.required('Please select at least one option.');
      }
    } else if (component.type === 'datetime') {
      component = component as DateTimeStepComponentSpec;
      v = yup.date().nullable();
      if (!skippable) {
        v = v.required('Please enter a date.');
      }
      if (component.date_mode === 'past') {
        v = v.max(
          new Date(Date.now() + ONE_DAY),
          'Please enter a date in the past.',
        );
      } else if (component.date_mode === 'past_including_time') {
        v = v.max(new Date(), 'Please enter a date in the past.');
      } else if (component.date_mode === 'future') {
        v = v.min(
          new Date(Date.now() - ONE_DAY),
          'Please enter a date in the future.',
        );
      }
      if (component.date_must_be_before) {
        // Ignore the time component of the date
        const date = new Date(component.date_must_be_before);
        date.setHours(0, 0, 0, 0);
        v = v.max(
          date,
          `Please enter a date before ${new Date(
            component.date_must_be_before,
          ).toLocaleDateString()}.`,
        );
      }
      if (component.date_must_be_after) {
        // Ignore the time component of the date
        const date = new Date(component.date_must_be_after);
        date.setHours(0, 0, 0, 0);
        v = v.min(
          date,
          `Please enter a date after ${new Date(
            component.date_must_be_after,
          ).toLocaleDateString()}.`,
        );
      }
    } else if (component.type === 'datetime_without_timezone') {
      component = component as DateTimeWithoutTimezoneStepComponentSpec;
      v = yup.object({ date: yup.string().nullable(), discovered: yup.bool() });
      if (component.required) {
        v = yup.object({
          date: yup.string().nullable().required('Please enter a date.'),
          discovered: yup.bool(),
        });
      }
      if (component.date_mode === 'past_up_to_now') {
        v = v.test(
          'is-past-up-to-now',
          'Please enter a date in the past.',
          value => {
            if (!value?.date) {
              return true;
            }
            const date = new Date(value.date);
            return date.getTime() <= Date.now() + UNKNOWN_TIMEZONE_BUFFER_MS;
          },
        );
      }
    } else if (component.type === 'vehicle_damage_picker') {
      if (component.required) {
        v = yup.object().nullable();
        v = v.test(
          'is-valid-damage',
          'Please mark the damage before continuing.',
          (value: any) => {
            return (
              value &&
              // The output of vehicle_damage_picker is an object with keys {meshType, [...]}.
              // When a user draws on the mesh, the data is stored in the keys other than meshType.
              // Only allow the user to proceed once they have drawn on the mesh, i.e. a non-meshType
              // key is non-empty.
              Object.keys(value).some(
                k => k !== 'meshType' && k !== 'meshVersion' && !!value[k],
              )
            );
          },
        );
      }
    } else if (component.type === 'vehicle_damage_region_picker') {
      v = yup
        .array()
        .required(
          'Please select one or more damage regions, or interior damage, before continuing.',
        );
    } else if (component.type === 'signature') {
      if (component.required) {
        v = yup.string().nullable().required('Please sign to continue.');
      }
    } else if (component.type === 'select') {
      if (component.required) {
        v = yup
          .mixed()
          .nullable()
          .required(
            component.required_no_selection_error_message ||
              'Please select an option to continue.',
          );
      }
    } else if (component.type === 'select_or_create') {
      if (component.required && !component?.create?.include_empty_option) {
        v = yup.object({
          [component?.create?.fields[0]?.key]: yup
            .string()
            .nullable()
            .required('Please select an option or enter a value to continue.')
            .test(
              'is-valid-full-name-if-required',
              'Please enter both a first and last name.',
              (value: any) => {
                const nameRequired =
                  (component as SelectOrCreateStepComponentSpec)?.create
                    ?.fields[0]?.mode === 'full_name';
                if (!nameRequired || !value) {
                  return true;
                }
                return /\w+\s+\w+/.test(value);
              },
            ),
        });
      }
    } else if (component.type === 'vehicle_seatmap' && component.single) {
      v = yup.string().nullable().required('Please select a seat to continue.');
    } else if (
      component.type === 'document' &&
      component.enforce_required_document_fields &&
      component.document_components.some(c => c.required)
    ) {
      v = yup.object().nullable();
      v = v.test(
        'is-valid-document',
        `Please complete all fields to continue.`,
        (value: any) => {
          component = component as DocumentStepComponentSpec;
          let contentField = component.content_field;
          const content = contentField ? value[contentField] : undefined;
          if (content) {
            const data = JSON.parse(content);
            return component.document_components.every(c => {
              if (c.required) {
                return !!data[c.field];
              }
              return true;
            });
          } else {
            return true;
          }
        },
      );
    } else if (
      component.type === 'document' &&
      component.document_type === 'INSURANCE_CARD'
    ) {
      const agencyPolicyNumber = component.document_components.find(
        d => d.field === 'agencyPolicyNumber',
      );

      if (
        agencyPolicyNumber &&
        (typeof agencyPolicyNumber.minimum === 'number' ||
          typeof agencyPolicyNumber.maximum === 'number')
      ) {
        const { minimum, maximum } = agencyPolicyNumber;

        let policyNumberSchema = yup
          .string()
          .trim()
          .transform(v => (v?.trim()?.length ? v : undefined));

        if (typeof minimum === 'number') {
          policyNumberSchema = policyNumberSchema.min(
            minimum,
            `The insurance policy number must be at least ${minimum} characters.`,
          );
        }

        if (typeof maximum === 'number') {
          policyNumberSchema = policyNumberSchema.max(
            maximum,
            `The insurance policy number must be at most ${maximum} characters.`,
          );
        }

        v = yup.object({
          filerInsuranceCardDocument: yup.mixed(),
          filerUserEnteredInsuranceCardDetails: yup
            .object()
            /**
             * Only apply policy number validation when a document has not been uploaded.
             * This allows a user to upload any document with a policy number, but require
             * specific length validation when the policy number is manually entered.
             */
            .when('filerInsuranceCardDocument', {
              // FIXME: Correct these types to be more specific
              is: (value: any) => !!value,
              then: (schema: any) => schema, // when there's a document uploaded, don't apply policy number validation
              otherwise: () =>
                yup.object({
                  agencyPolicyNumber: agencyPolicyNumber.required
                    ? policyNumberSchema.required(
                        'Please enter a valid insurance policy number.',
                      )
                    : policyNumberSchema.optional(),
                }),
            }),
        });
      }
    } else if (component.type === 'party_adder') {
      const partyAdderDataEntry = yup.object({
        id: yup.string().optional(),
        wasSeenAsHitAndRunParty: yup.boolean().optional(),
        partyType: yup.mixed().when('wasSeenAsHitAndRunParty', {
          is: (wasSeenAsHitAndRunParty: boolean) =>
            wasSeenAsHitAndRunParty === true ||
            wasSeenAsHitAndRunParty === undefined,
          then: (schema: any) =>
            schema.oneOf(['Automobile', 'Ped', 'Bike', 'Trailer']).required(),
          otherwise: (schema: any) =>
            schema.oneOf(['Automobile', 'Ped', 'Bike', 'Trailer']).optional(),
        }),

        isReportingParty: yup.boolean().optional(),
        isInsuredByCarrier: yup.boolean().optional(),
        name: yup.string().when(['partyType', 'wasSeenAsHitAndRunParty'], {
          is: (partyType: string, wasSeenAsHitAndRunParty: boolean) => {
            if (wasSeenAsHitAndRunParty === false) {
              return true;
            }
            return partyType === 'Automobile' || partyType === 'Trailer';
          },
          then: (schema: any) => schema.nullable().optional(),
          otherwise: (schema: any) =>
            schema
              .nullable()
              .test('is-not-undefined', (v: any) => typeof v !== 'undefined'),
        }),
        vehicle: yup
          .object({
            color: yup.string().nullable(),
            make: yup.string().nullable(),
          })
          .default(undefined)
          .when(
            ['partyType', 'didHitAndRun', 'wasSeenAsHitAndRunPartydRunParty'],
            {
              is: (
                partyType: string,
                didHitAndRun: boolean,
                wasSeenAsHitAndRunParty: boolean,
              ) => {
                if (wasSeenAsHitAndRunParty === false) {
                  return false;
                }

                if (wasSeenAsHitAndRunParty === true) {
                  return partyType === 'Automobile';
                }

                return partyType === 'Automobile' && !didHitAndRun;
              },
              then: (schema: any) =>
                schema.required().test('has-color-and-make', (v: any) => {
                  return (
                    typeof v?.color !== 'undefined' &&
                    typeof v?.make !== 'undefined'
                  );
                }),
            },
          ),
        didHitAndRun: component.collect_hit_and_run
          ? yup.boolean().when(['partyType', 'wasSeenAsHitAndRunParty'], {
              is: (partyType: string, wasSeenAsHitAndRunParty: boolean) => {
                if (wasSeenAsHitAndRunParty === false) {
                  return false;
                }

                return partyType === 'Automobile';
              },
              then: (schema: any) => schema.required(),
            })
          : yup.boolean(),
        didNotMakeContact: component.collect_no_contact
          ? yup.boolean().when(['partyType', 'wasSeenAsHitAndRunParty'], {
              is: (partyType: string, wasSeenAsHitAndRunParty: boolean) => {
                if (wasSeenAsHitAndRunParty === false) {
                  return false;
                }

                return partyType === 'Automobile';
              },
              then: (schema: any) => schema.required(),
            })
          : yup.boolean(),
      });
      v = yup.array().of(partyAdderDataEntry).required();
    } else if (component.type === 'location') {
      if (component.required) {
        v = yup
          .object()
          .nullable()
          .required('Please select a location to continue.');
      }
    } else if (component.type === 'intersection_wizard') {
      if (component.required) {
        v = yup
          .mixed()
          .nullable()
          .required('Please select a street to continue.')
          .test(
            'required',
            'Please select a street to continue.',
            (value: any) => {
              return typeof value === 'string' ? !!value : !!value.addressText;
            },
          );
      }
    } else if (component.type === 'license_plate_selector') {
      if (component.required) {
        v = yup
          .object({
            licensePlateState: yup.string().nullable().required(),
            licensePlate: yup.string().nullable(),
          })
          .required('Please enter the license plate details.');
      }
    } else if (component.type === 'underwriting_memo_phone') {
      v = yup
        .array()
        .required('Please enter a phone number.')
        .test('required', 'Please enter a phone number.', (value: any) => {
          return isUnderwritingMemoPhoneReady(value);
        });
    } else if (component.type === 'vehicle_occupant_entry_wizard') {
      v = yup.object().nullable();
      v = v.test(
        'seat-selection-email-validation',
        'Please enter a valid email address.',
        (value: any) => {
          if (!value) {
            return true;
          }
          const { emailAddress } =
            (value as OccupantEntryStepComponentSpec).occupantDetails ?? {};
          return yup
            .string()
            .nullable()
            .email('Please enter a valid email address')
            .isValidSync(emailAddress);
        },
      );
    } else if (component.type === 'vehicle_speed') {
      v = yup.object({
        speedBeforeCollision: yup.number().nullable(),
        wasStopped: yup.boolean().nullable(),
        wasReversing: yup.boolean().nullable(),
      });
      v = v.test(
        'speed-validation',
        'Please enter a valid speed.',
        (value: any) => {
          if (!value) {
            return true;
          }
          return value.speedBeforeCollision > 0 || value.wasStopped === true;
        },
      );
    }

    return v;
  };

  const makeAdditionalComponentSpec = (component: AnyStepComponentSpec) => {
    let v;
    if (
      component.field &&
      'other_field' in component &&
      component.other_field &&
      component.other_field_mode === 'full_name'
    ) {
      v = yup.string().when(component.field, value => {
        if (JSON.stringify(value) === JSON.stringify(component.other_option)) {
          return yup
            .string()
            .test(
              'is-valid-full-name-if-present',
              'Please enter both a first and last name.',
              function (value: any) {
                if (!!value && typeof value === 'string') {
                  return /\w+\s+\w+/.test(value);
                }
                return false;
              },
            );
        }
        return yup.string().nullable();
      });
      return { [component.other_field]: v };
    }
  };

  for (const component of step.content.step_components) {
    let v = makeComponentSpec(component);
    if (component.field && v) {
      Object.assign(shape, {
        [escapeComponentJSONFieldName(component.field)]: v,
        ...makeAdditionalComponentSpec(component),
      });
    }
    if (component.type === 'toggleable_group') {
      component.step_components.forEach(step => {
        let v = makeComponentSpec(step);
        if (step.field && v) {
          Object.assign(shape, {
            [escapeComponentJSONFieldName(step.field)]: v,
            ...makeAdditionalComponentSpec(step),
          });
        }
      });
    }
  }

  const schema = yup.object().shape(shape);
  return schema;
};

/**
 * Some component field names are JSON. Because of the way yup transforms object keys, we need to
 * escape the quotations in the field names to avoid errors.
 */
export const escapeComponentJSONFieldName = (field: string) => {
  return field.replaceAll('"', '%');
};

export const unescapeComponentJSONFieldName = (field: string) => {
  return field.replaceAll('%', '"');
};
