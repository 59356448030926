import { DisplayMode, RegionTemplate } from './types';

export const torso: { torso: RegionTemplate } = {
  torso: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'ribs',
        label: 'Ribs',
      },
      {
        key: 'groin',
        label: 'Groin',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
      {
        key: 'spleen',
        label: 'Spleen',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'liver',
        label: 'Liver',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'kidney',
        label: 'Kidney',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'organs',
            label: 'Were organs damaged?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        tabs: ['skin'],
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'seatbelt_bruise',
        label: 'Seatbelt bruise',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: [DisplayMode.AUTO, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'broken_rib',
        label: 'Broken rib',
        tabs: ['ribs'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_rib',
        label: 'Bruised rib',
        tabs: ['ribs'],
        popular_in_modes: [DisplayMode.AUTO],
      },

      {
        key: 'hernia',
        label: 'Abdominal hernia',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['groin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'genital_damage',
        label: 'Genital damage',
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        tabs: ['groin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: [],
        popular_in_modes: [DisplayMode.AUTO],
      },
      {
        key: 'rupture_spleen',
        label: 'Rupture',
        tabs: ['spleen'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'rupture_liver',
        label: 'Rupture',
        tabs: ['liver'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'rupture_kidney',
        label: 'Rupture',
        tabs: ['kidney'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'puncture_spleen',
        label: 'Puncture',
        tabs: ['spleen'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },

      {
        key: 'puncture_liver',
        label: 'Puncture',
        tabs: ['liver'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'puncture_kidney',
        label: 'Puncture',
        tabs: ['kidney'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'burn_scald',
        label: 'Burn / scaled',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'majorBurn',
            label: 'Major burn?',
          },
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'dermatitis',
        label: 'Dermatitis',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'infection',
        label: 'Infection',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'spleen_inflammation',
        label: 'Inflammation',
        tabs: ['spleen'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'spleen_laceration',
        label: 'Laceration',
        tabs: ['spleen'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'spleen_infection',
        label: 'Infection',
        tabs: ['spleen'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'kidney_inflammation',
        label: 'Inflammation',
        tabs: ['kidney'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'kidney_laceration',
        label: 'Laceration',
        tabs: ['kidney'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'kidney_infection',
        label: 'Infection',
        tabs: ['kidney'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'liver_inflammation',
        label: 'Inflammation',
        tabs: ['liver'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'liver_laceration',
        label: 'Laceration',
        tabs: ['liver'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'liver_infection',
        label: 'Infection',
        tabs: ['liver'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
  },
};
