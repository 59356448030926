import { MedicalProviderOrganizationType } from '../../HealthProvider';
import DocumentType from '../Document';
import { LocationType } from '../LocationType';
import { MediaType } from '../Media';
import { PassengerType } from './Passenger';
import { VehicleType } from './Vehicle';

export enum ChildCarSeatType {
  BOOSTER_SEAT = 'BOOSTER_SEAT',
  CHILD_SEAT = 'CHILD_SEAT',
}

export enum VehicleOccupantSeat {
  DRIVER = 'DRIVER',
  PASSENGER = 'PASSENGER',
  SECOND_ROW_DRIVER_SIDE = 'SECOND_ROW_DRIVER_SIDE',
  SECOND_ROW_MIDDLE = 'SECOND_ROW_MIDDLE',
  SECOND_ROW_PASSENGER_SIDE = 'SECOND_ROW_PASSENGER_SIDE',
  THIRD_ROW_DRIVER_SIDE = 'THIRD_ROW_DRIVER_SIDE',
  THIRD_ROW_MIDDLE = 'THIRD_ROW_MIDDLE',
  THIRD_ROW_PASSENGER_SIDE = 'THIRD_ROW_PASSENGER_SIDE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

// FIXME: This is a duplicate of an enum in Prisma. Trying to import it from
// prisma was breaking the build, so I copied it here.
enum TripWorkType {
  Uber = 'Uber',
  UberEats = 'UberEats',
  Lyft = 'Lyft',
  Other = 'Other',
}

export interface ChildCarSeat {
  id: string;

  involvedParty: InvolvedPartyType;
  involvedPartyId?: string;

  seat?: VehicleOccupantSeat | 'UNKNOWN';
  type?: ChildCarSeatType;

  wasOccupied?: boolean;
  wasDamaged?: boolean;
}

export enum RepairIntent {
  CarrierCoordinated = 'CARRIER_COORDINATED',
  Payout = 'PAYOUT',
  Undecided = 'UNDECIDED',
  CustomerCoordinated = 'CUSTOMER_COORDINATED',
}

export enum RentalIntent {
  ACCEPTED_COVERED = 'ACCEPTED_COVERED',
  ACCEPTED_NOT_COVERED = 'ACCEPTED_NOT_COVERED',
  DECLINED_COVERED = 'DECLINED_COVERED',
  DECLINED_NOT_COVERED = 'DECLINED_NOT_COVERED',
}

export interface InvolvedPartyType {
  id?: string;
  type: string;

  insuredName?: string;
  insuredVehicle?: VehicleType;

  agency?: string;
  agencyPolicyNumber?: string;
  agencyPolicyExpiration?: string;
  agencyPhoneNumber?: string;
  agencyNaic?: string;

  userEnteredDescription?: string;

  personName?: string;
  personPhoneNumber?: string;

  userContent?: string;
  // FIXME(01-20-2020) JSON type
  // FIXME(01-20-2020) Revamp for standardized fields

  insuranceCardDocument?: DocumentType;
  driverLicenseDocument?: DocumentType;

  licensePlateMedia?: MediaType;

  manualVehicleLicensePlate?: string;
  manualVehicleLicensePlateState?: string;

  incidentDirectionOfTravel?: number;

  passengers: PassengerType[];

  vehicleMovementState?: string;

  airbagsDeployed: boolean;
  drivableAfterIncident: boolean;
  wheelsDamaged: boolean;
  doorsDamaged: boolean;
  fluidsLeaking: boolean;
  leftRoadway: boolean;

  vehicleWasDamaged?: boolean;
  vehicleDamageMesh?: string;
  vehicleDamageRegions?: { region: string }[];

  driverWasInjured?: boolean;
  driverInjurySeverity?: string;
  driverInjuryNightsHospitalized?: number;
  driverBodilyInjuryData?: string;

  vehicleHadPriorDamage?: boolean;
  vehiclePriorDamageMesh?: string;
  vehiclePriorDamageRegions?: { region: string }[];

  repairEstimateAmount?: number;
  rentalIntent?: RentalIntent;

  theftVehicleWasRecovered?: boolean;
  theftVehicleWasCarjacking?: boolean;
  theftVehicleWasAttemptedOnly?: boolean;
  vehicleRecoveryLocation?: LocationType;

  tripDepartedFromLocation?: LocationType;
  tripHeadingToLocation?: LocationType;
  tripWasRideshareOrDelivery?: boolean;
  tripWasRideshare?: boolean;
  tripWasDelivery?: boolean;
  tripWasCarshare?: boolean;
  tripWasWorkRelated?: boolean;
  tripWasWorkCommute?: boolean;
  tripWorkType?: TripWorkType;

  userOccupant: {
    // more properties exist in actuality, so we should populate them
    // here on an "as-needed" basis
    medicalProviderOrganizations?: MedicalProviderOrganizationType[];
    contactAddress?: LocationType;
  };

  pedestrianContact: {
    medicalProviderOrganizations?: MedicalProviderOrganizationType[];
    contactAddress?: LocationType;
    wasInjured?: boolean;
    injurySeverity?: string;
    injuryNightsHospitalized?: number;
    bodilyInjuryData?: string;
  };

  vehicleRequestedBodyShop?: string;
  vehicleBodyShopState?: string;
  vehicleBodyShopLocation?: LocationType;

  rentalRequestedPickupLocation?: string;
  carSeatUsed?: boolean;
  seatsWithChildCarSeats?: VehicleOccupantSeat[];
  childCarSeats?: ChildCarSeat[];
  lawsuitWasFiled?: boolean;
  nameOfCouncilForLawsuit?: string;
  repairIntent?: RepairIntent;
  wasStruckInAnimalInteraction?: boolean;
  wasInvolvedInAnimalInteraction?: boolean;

  wasPetPresentInVehicle?: boolean;
}
